import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <section className='hero is-medium'>
      <div className='hero-body pb-0'>
        <div className='column is-three-fifths is-offset-one-fifth'>
          <div className='section is-centered'>
            <h1 className='title is-size-1 has-text-weight-bold'>
              404 not found
            </h1>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
